<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-8">
      <span class="text-h5"><strong> Donor Notification</strong></span>
      <v-btn color="primary" :disabled="deleteBtn" @click="addDonorModal = true"> Add Notification</v-btn>
    </v-card-title>
    <v-row class="ml-2 mr-2 pt-3 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.form_id" label="Notification Form Id" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Donor's Name" v-model="filter.donor_name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Donor's Phone No." v-model="filter.phone_no" outlined dense hide-details counter="10"
          @keypress="onlyNumber"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Referral By" v-model="filter.referral_name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="">
        <v-btn color="primary" class="mr-2" @click="searchDonorNotification()"> Search</v-btn>
        <v-btn color="primary" class="mr-8" @click="refreshTable()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-btn color="primary" class="mr-8" @click="SystemUserList()">Refresh</v-btn>
        <v-btn color="primary" class="mr-8" @click="addUserModal = true"> Add User</v-btn>
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :loading="listLoading" :items="donorList" :items-per-page="10" class="table-rounded"
      fixed-header height="400" item-key="donor_id" :single-expand="singleExpand" :expanded.sync="expanded" show-expand>
      <template #[`item.date`]="{ item }">
        <pre>{{ moment.utc(item.date).format('DD-MM-YYYY HH:mm') }}</pre>
      </template>
      <template #[`item.operate`]="{ item }">
        <v-btn color="primary" class="mr-3" @click="donorDeatilView(item)"> View </v-btn>
        <v-btn color="primary" text class="mr-3" @click="editNotificationDonor(item)"> Edit </v-btn>
        <EditDonorNotification :CenterCity="CenterCity" :visible="editDonorNotification" :item="editItem"
          :method="getdonorList" @close="editDonorNotification = false" />
        <v-btn color="error" text @click="deleteDonorNotifications(item)" :disabled="true"> Delete </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pt-3 col-12">
            <v-col sm="6" md="6">
              <p><strong> General Information </strong></p>
              <p>Donor Age: {{ item.age }}</p>
              <p>Donor Sex: {{ item.sex }}</p>
              <p>Phone: {{ item.phone_no }}</p>
              <p>Relative Name:{{ item.donor_relative_name }}</p>
            </v-col>
            <v-col sm="6" md="6">
              <p><strong> Referral Information</strong></p>
              <p>Requesting Person: {{ item.category_type_of_the_requesting_person }}</p>
              <p>Referral Phone No: {{ item.referral_contact_number }}</p>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="deleteDonorNotification(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDonorModal" max-width="1140">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong>ADD DONOR NOTIFICATION</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="DonorNotificationList.donorName" outlined dense :rules="nameee" hide-details
                  label="Donor's Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <p class="mb-0">Parent/Guardian*:</p>
                <v-radio-group row class="mt-0" v-model="DonorNotificationList.s_w_d_of" :rules="nameee">
                  <v-radio name="Parents_name" label="S" value="Son"></v-radio>
                  <v-radio name="Parents_name" label="W" value="Wife"></v-radio>
                  <v-radio name="Parents_name" label="D" value="Daughter"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field outlined dense :rules="nameee" hide-details
                  v-model="DonorNotificationList.donor_parent_guardian_name" label="Parent/Guardian Name*" required>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="DonorNotificationList.donorAge" outlined dense :rules="nameee" label="Donor's Age*"
                  required @paste.prevent @keypress="onlyAge"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <p class="mb-0">Sex*:</p>
                <v-radio-group row class="mt-0" v-model="DonorNotificationList.sex">
                  <v-radio name="sex" label="Male" value="Male"></v-radio>
                  <v-radio name="sex" label="Female" value="Female"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea v-model="DonorNotificationList.address" outlined dense :rules="nameee" hide-details
                      label="Postal Address*" required rows="6" row-height="15"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- <p></p> -->
                    <v-radio-group row class="mt-0" v-model="DonorNotificationList.urban_rural">
                      <v-radio name="sex" label="Urban" value="Urban"></v-radio>
                      <v-radio name="sex" label="Rural" value="Rural"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="DonorNotificationList.chapters" outlined dense :rules="nameee"
                      :items="CenterCity" label="Handling Chapters*" item-text="chapter"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="DonorNotificationList.contactNumber" outlined dense :rules="nameee"
                      label="Phone No*" required counter="10" @paste.prevent @keypress="onlyNumber"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field outlined dense :rules="nameee" hide-details
                      v-model="DonorNotificationList.donorRelativeName" label="Relative Name*" required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="pb-0">
                    <p class="mb-0">Relationship with Donor*:</p>
                    <v-radio-group row class="mt-0" v-model="DonorNotificationList.relation_with_donor"
                      v-on="relationWithDonor()">
                      <v-radio name="donor_relation" label="Son" value="Son"></v-radio>
                      <v-radio name="donor_relation" label="Wife" value="Wife"></v-radio>
                      <v-radio name="donor_relation" label="Daughter" value="Daughter"></v-radio>
                      <v-radio name="donor_relation" label="Others" value="Others"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="12" v-if="DonorNotificationList.relation_with_donor === 'Others'" class="pt-0">
                    <v-text-field outlined dense :rules="nameee" hide-details
                      v-model="DonorNotificationList.donorOtherRelationshipName" label="Relationship Name*"
                      required></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense hide-details v-model="DonorNotificationList.person" label="Referral By"
                      required readonly @focusin="toggleReadonly" @focusout="toggleReadonly"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Referral Contact No." required readonly
                      v-model="DonorNotificationList.referral_contact_number" counter="10" @keypress="onlyNumber"
                      @focusin="toggleReadonly" @focusout="toggleReadonly"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <p class="mb-0">Referred Person</p>
                <v-radio-group row class="mt-0" @change="checkEyeBankStaff()"
                  v-model="DonorNotificationList.category_type_of_the_requesting_person">
                  <v-radio name="Parents_name" label="Eye Bank Staff" value="Eye Bank Staff"></v-radio>
                  <v-radio name="Parents_name" label="Hospital Staff" value="Hospital Staff"></v-radio>
                  <v-radio name="Parents_name" label="Volunteer" value="Volunteer"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="DonorNotificationList.cause_of_death" outlined dense :rules="nameee"
                  label="Cause of Death*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-datetime-picker v-model="deathDate" dateFormat="dd-MM-yyyy" time-format="HH:mm" :rules="nameee"
                  label="Date and Time of Death DD-MM-YYYY HH:mm*" :text-field-props="textFieldProps"></v-datetime-picker>

              </v-col>
            </v-row>
          </v-container>
          <small class="ml-3">*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="samedata()"> Cancel </v-btn>
          <v-btn color="primary" @click="DonorNotification()" :disabled="!formIsValid"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ConfirmModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Continue to Next Form</v-card-title>
        <v-card-text> Are you sure you want to go to the Next Form ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="ConfirmModal = false"> Cancel 
          </v-btn>
          <v-btn color="primary" @click="confirmTONext()"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="viewDonorModal" max-width="1140">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong>DONOR NOTIFICATION DETAIL</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="createdDate" outlined dense label="Date: DD-MM-YYYY HH:mm (Web)" readonly
                  hide-details>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
              </v-col>

              <v-col cols="12" md="2">
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field v-if="viewList.mobile_notification !== null" v-model="createdDateMobile" outlined dense
                  label="Mobile Date: DD-MM-YYYY HH:mm (Notification)" readonly hide-details>
                </v-text-field>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="viewList.donor_name" outlined dense label="Donor's Name" readonly hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <p class="mb-0">Parent/Guardian:</p>
                <v-radio-group row class="mt-0" v-model="viewList.s_w_d_of" readonly>
                  <v-radio name="Parents_name" label="S" value="Son"></v-radio>
                  <v-radio name="Parents_name" label="W" value="Wife"></v-radio>
                  <v-radio name="Parents_name" label="D" value="Daughter"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field outlined dense v-model="viewList.donor_parent_guardian_name" label="Parent/Guardian Name"
                  readonly hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="viewList.age" outlined dense label="Donor's Age" readonly hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <p class="mb-0">Sex:</p>
                <v-radio-group row class="mt-0" v-model="viewList.sex" readonly>
                  <v-radio name="sex" label="Male" value="Male"></v-radio>
                  <v-radio name="sex" label="Female" value="Female"></v-radio>
                </v-radio-group>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6">
                <v-textarea
                  v-model="viewList.postal_address"
                  outlined
                  dense
                  label="Postal Address"
                  readonly
                  rows="8"
                  row-height="15"
                  hide-details
                ></v-textarea>
              </v-col> -->
              <v-col cols="12" sm="6" md="6">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea v-model="viewList.postal_address" outlined dense hide-details label="Postal Address"
                      required rows="6" row-height="15"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-radio-group row class="mt-0" v-model="viewList.urban_rural">
                      <v-radio name="sex" label="Urban" value="Urban"></v-radio>
                      <v-radio name="sex" label="Rural" value="Rural"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="viewList.chapters" outlined dense :items="CenterCity"
                      label="Handling Chapters" item-text="chapter"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="viewList.phone_no" outlined dense label="Phone No" readonly hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field outlined dense v-model="viewList.donor_relative_name" label="Relative Name" readonly
                      hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <p class="mb-0 mt-0">Relative relation with Donor:</p>
                    <v-radio-group row class="mt-0" v-model="viewList.relation_with_donor" readonly>
                      <v-radio name="donor_relation" label="Son" value="Son"></v-radio>
                      <v-radio name="donor_relation" label="Wife" value="Wife"></v-radio>
                      <v-radio name="donor_relation" label="Daughter" value="Daughter"></v-radio>
                      <v-radio name="donor_relation" label="Others" value="Others"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="12" v-if="viewList.relation_with_donor === 'Others'" class="pt-0">
                    <v-text-field outlined dense :rules="nameee" hide-details
                      v-model="viewList.donorOtherRelationshipName" label="Relationship Name" required></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="viewList.referral_name" label="Referral Name" readonly
                      hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Referral Contact No." readonly hide-details
                      v-model="viewList.referral_contact_number"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <p class="mb-0">Requesting Person</p>
                <v-radio-group row class="mt-0" v-model="viewList.category_type_of_the_requesting_person" readonly>
                  <v-radio name="Parents_name" label="Eye Bank Staff" value="Eye Bank Staff"></v-radio>
                  <v-radio name="Parents_name" label="Hospital Staff" value="Hospital Staff"></v-radio>
                  <v-radio name="Parents_name" label="Volunteer" value="Volunteer"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="viewList.cause_of_death" outlined dense readonly label="Cause of Death" required>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <!-- <v-datetime-picker
                  v-model="deathDate"
                  time-format="HH:mm:ss"
                  label="Date and Time of Death"
                  :text-field-props="textFieldProps"
                ></v-datetime-picker> -->
                <v-text-field v-model="viewList.date_of_time_of_death" outlined dense
                  label="Date and Time of Death YYYY-MM-DD HH:mm:ss" readonly hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="viewDonorModal = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import Centerservice from '@/service/Branches.service'
import axios from 'axios'
import EditDonorNotification from './EditDonorNotification.vue'
import moment from 'moment'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const baseUrl = 'https://api.stage.ebsr.in/'
const userName = localData.user_name
const userId = localData.user_id
const userNumber = localData.user_number
import { api } from '@/config/config'

export default {
  components: {
    EditDonorNotification,
  },

  data() {
    return {
      nameee: [
        v => !!v,
      ],
      donor_item: '',
      ConfirmModal: false,
      CenterCity: [{ chapter: '' }],
      expanded: [],
      singleExpand: true,
      moment,
      loginUserName: userName,
      loginUserNumber: userNumber,
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      editItem: '',
      DeleteModal: false,
      timeout: 2000,
      thisItemId: '',
      deleteBtn: true,
      headers: [
        { text: 'Donor Id', value: 'donor_id', align: ' d-none' },
        { text: 'User Id', value: 'user_id', align: ' d-none' },
        { text: 'Notification Id', value: 'form_id', width: '250px' },
        { text: 'Notification Date', value: 'date', width: '150px' },
        { text: "Donor Name", value: 'donor_name', width: '150px' },
        // { text: 'Donor Parent/Guardian Name', value: 'donor_parent_guardian_name', width: '150px' },
        // { text: 'Donor Parent/Guardian Relation', value: 's_w_d_of', width: '150px' },
        // { text: "Donor's Age", value: 'age', width: '80px' },
        // { text: "Donor's sex", value: 'sex', width: '80px' },
        // { text: 'Postal Address', value: 'postal_address', width: '150px' },
        // { text: "Donor's Phone", value: 'phone_no', width: '150px' },
        // { text: 'Donor Relative Name', value: 'donor_relative_name', width: '150px' },
        // { text: 'Relationship with Donor', value: 'relation_with_donor', width: '150px' },
        { text: 'Referral By', value: 'referral_name', width: '140px' },
        // { text: 'Requesting Person', value: 'category_type_of_the_requesting_person', width: '150px' },
        // { text: "Referral Contact No.", value: 'referral_contact_number', width: '150px',},
        { text: 'OPERATE', value: 'operate', width: '345px' },
      ],
      viewList: [],
      itemDonorId: '',
      textFieldProps: {
        outlined: true,
        dense: true,
        'hide-details': true,
      },
      donorList: [],
      DonorNotificationList: {
        urban_rural: '',
        donorName: '',
        donor_parent_guardian_name: '',
        s_w_d_of: '',
        donorAge: '',
        sex: '',
        address: '',
        contactNumber: '',
        donorRelativeName: '',
        relation_with_donor: '',
        person: userName,
        category_type_of_the_requesting_person: 'Eye Bank Staff',
        referral_contact_number: userNumber,
        user_id: '',
        referral_by_id: userId,
        cause_of_death: '',
        date_of_time_of_death: '',
        chapters: '',
        donorOtherRelationshipName: '',
      },
      addDonorModal: false,
      editDonorNotification: false,
      viewDonorModal: false,
      filter: {
        form_id: '',
        donor_name: '',
        phone_no: '',
        referral_name: '',
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getdonorList()
      },
      deep: true,
    },
  },

  computed: {

    formIsValid() {
      return (
        this.DonorNotificationList.donorName &&
        this.DonorNotificationList.donor_parent_guardian_name &&
        this.DonorNotificationList.s_w_d_of &&
        this.DonorNotificationList.donorAge &&
        this.DonorNotificationList.sex &&
        this.DonorNotificationList.address &&
        this.DonorNotificationList.contactNumber &&
        this.DonorNotificationList.donorRelativeName &&
        this.DonorNotificationList.relation_with_donor &&
        this.DonorNotificationList.person &&
        this.DonorNotificationList.category_type_of_the_requesting_person &&
        this.DonorNotificationList.referral_contact_number &&
        this.DonorNotificationList.cause_of_death &&
        this.DonorNotificationList.date_of_time_of_death &&
        this.DonorNotificationList.chapters &&
        this.DonorNotificationList.urban_rural
      )
    },

    deathDate: {
      get() {
        return this.DonorNotificationList.date_of_time_of_death
          ? moment(this.DonorNotificationList.date_of_time_of_death).format('DD-MM-YYYY HH:mm')
          : ''
      },
      set(value) {
        this.$set(this.DonorNotificationList, 'date_of_time_of_death', moment(value).format('YYYY-MM-DD HH:mm'),)
      },
    },

    createdDate: {
      get() {
        return moment(this.viewList.date).subtract("05:30").format('DD-MM-YYYY HH:mm')
      },
    },

    createdDateMobile: {
      get() {
        return moment(this.viewList.mobile_notification).subtract("05:30").format('DD-MM-YYYY HH:mm')
      },
    },
  },

  async mounted() {
    this.getdonorList()
    this.GetCenter()
    this.checkPermission()
  },

  methods: {

    checkPermission() {
      const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
      console.log(localData);
      if (localData.userDesignation == "Counsellor Cum Technician") {
        this.deleteBtn = false;
      }
    },

    relationWithDonor() {
      if (this.DonorNotificationList.relation_with_donor != "Others") {
        this.DonorNotificationList.donorOtherRelationshipName = ""
      }
    },


    samedata() {
      this.addDonorModal = false
      window.location.reload()
      // this.DonorNotificationList.donorName = ''
      // this.DonorNotificationList.donor_parent_guardian_name = ''
      // this.DonorNotificationList.s_w_d_of = ''
      // this.DonorNotificationList.donorAge = ''
      // this.DonorNotificationList.sex = ''
      // this.DonorNotificationList.address = ''
      // this.DonorNotificationList.contactNumber = ''
      // this.DonorNotificationList.donorRelativeName = ''
      // this.DonorNotificationList.relation_with_donor = ''
      // this.DonorNotificationList.cause_of_death =''
      // this.DonorNotificationList.date_of_time_of_death = null
      // this.DonorNotificationList.chapters =''
      // this.DonorNotificationList.urban_rural =''
      // if(this.DonorNotificationList.category_type_of_the_requesting_person != 'Eye Bank Staff'){
      //   this.DonorNotificationList.category_type_of_the_requesting_person = ''
      //   this.DonorNotificationList.referral_contact_number = ''
      //   this.DonorNotificationList.person =''
      // }

    },

    same() {
      // if (this.DonorNotificationList.contactNumber.length < 10) {
      //   return false, (this.snackbarText = 'Phone number should be 10 Digit.'), (this.snackbar = true)
      // }
      // if (this.DonorNotificationList.referral_contact_number.length < 10) {
      //   return false, (this.snackbarText = 'Phone number should be 10 Digit.'), (this.snackbar = true)
      // }
      // this.DonorNotification()
      this.ConfirmModal = true

    },

    async GetCenter() {
      const service = new Centerservice()
      let response = await service.BranchesList()
      if (response) {
        (response.data).map((item, i) => {
          response.data[i].chapter = `${item.center_name} (${item?.city})`
        });
        this.CenterCity = response.data
      } else {
        this.CenterCity = []
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    onlyAge($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault()
      }
    },

    editNotificationDonor(e) {
      console.log("check event", e);
      e.chapters = `${e.chapters} (${e?.center_city})`;

      this.editDonorNotification = true
      this.editItem = e
    },

    deleteDonorNotifications(e) {
      this.DeleteModal = true
      this.thisItemId = e.donor_id
    },
    confirmTONext() {
      this.$router.push({ name: 'Form_A', params: { donor_id: this.donor_item } });
    },

    async refreshTable() {
      this.getdonorList()
      this.filter.form_id = ''
      this.filter.donor_name = ''
      this.filter.phone_no = ''
      this.filter.referral_name = ''
    },

    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()

      if (response) {
        this.donorList = response.data
        this.listLoading = false
        this.donor_item = response.data[0].donor_id

      } else {
        this.donorList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async donorDeatilView(e) {
      this.itemDonorId = e.donor_id
      const service = new donorService()
      let response = await service.donorNotificationDetail(e.donor_id)
      this.viewDonorModal = true
      if (response) {
        (response).map((item, i) => {
          response[i].chapter = `${item.center_name} (${item?.center_city})`
        })

        this.viewList = response[0]
        this.viewList.date_of_time_of_death = moment(response[0].date_of_time_of_death).subtract("05:30").format('DD-MM-YYYY HH:mm')

      } else {
        this.viewList = []
      }
    },

    async checkEyeBankStaff() {
      this.toggleReadonly(event)
      if (this.DonorNotificationList.category_type_of_the_requesting_person == 'Hospital Staff') {
        this.DonorNotificationList.person = ''
        this.DonorNotificationList.referral_contact_number = ''
        this.DonorNotificationList.referral_by_id = ''
      } else if (this.DonorNotificationList.category_type_of_the_requesting_person == 'Volunteer') {
        this.DonorNotificationList.person = ''
        this.DonorNotificationList.referral_contact_number = ''
        this.DonorNotificationList.referral_by_id = ''
      } else {
        this.DonorNotificationList.person = userName
        this.DonorNotificationList.referral_contact_number = userNumber
        this.DonorNotificationList.referral_by_id = userId
      }
    },

    async toggleReadonly(event) {
      event.preventDefault()
      if (this.DonorNotificationList.category_type_of_the_requesting_person == 'Eye Bank Staff') {
        event.target.setAttribute('readonly', 'readonly')
      } else {
        event.target.removeAttribute('readonly')
      }
    },

    async DonorNotification() {

      if (this.DonorNotificationList.relation_with_donor == "Others" && this.DonorNotificationList.donorOtherRelationshipName == "") {
        this.snackbarText = "Please fill the Relationship Name"
        this.snackbar = true
      }

      let str = this.DonorNotificationList.chapters;

      let spl = (str.split("("))[1];

      let chapters = ((str.split("("))[0]).trim();


      console.log("********* ", spl);

      let center_city = (spl.split(")"))[0];

      console.log("********* ", center_city);

      const data = {
        donor_name: this.DonorNotificationList.donorName,
        donor_parent_guardian_name: this.DonorNotificationList.donor_parent_guardian_name,
        s_w_d_of: this.DonorNotificationList.s_w_d_of,
        age: this.DonorNotificationList.donorAge,
        sex: this.DonorNotificationList.sex,
        postal_address: this.DonorNotificationList.address,
        urban_rural: this.DonorNotificationList.urban_rural,
        phone_no: this.DonorNotificationList.contactNumber,
        donor_relative_name: this.DonorNotificationList.donorRelativeName,
        relation_with_donor: this.DonorNotificationList.relation_with_donor,
        referral_name: this.DonorNotificationList.person,
        cause_of_death: this.DonorNotificationList.cause_of_death,
        category_type_of_the_requesting_person: this.DonorNotificationList.category_type_of_the_requesting_person,
        referral_contact_number: this.DonorNotificationList.referral_contact_number,
        date_of_time_of_death: this.DonorNotificationList.date_of_time_of_death,
        chapters: chapters,
        center_city: center_city,
        form_type: '1',
        referral_by_id: userId,
        user_id: userId,
        donorOtherRelationshipName: this.DonorNotificationList.donorOtherRelationshipName,
      }
      try {
        const response = await api.post(`donorNotification/add_donor_notification`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == 200) {
          this.same()
          this.addDonorModal = false
          this.snackbarText = response.data.message
          this.getdonorList()
          this.DonorNotificationList.donorName = ''
          this.DonorNotificationList.donor_parent_guardian_name = ''
          this.DonorNotificationList.s_w_d_of = ''
          this.DonorNotificationList.donorAge = ''
          this.DonorNotificationList.sex = ''
          this.DonorNotificationList.address = ''
          this.DonorNotificationList.contactNumber = ''
          this.DonorNotificationList.donorRelativeName = ''
          this.DonorNotificationList.relation_with_donor = ''
          this.DonorNotificationList.donorOtherRelationshipName = ''
          // this.DonorNotificationList.person = ''
          // this.DonorNotificationList.category_type_of_the_requesting_person = ''
          // this.DonorNotificationList.referral_contact_number = ''
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async deleteDonorNotification(item) {
      const data = {
        donor_id: item,
      }
      try {
        const response = await axios.post(`${baseUrl}donorNotification/delete`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        console.log('response', response)
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getdonorList()
          this.DeleteModal = false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async searchDonorNotification() {
      if (this.filter.form_id || this.filter.donor_name || this.filter.referral_name || this.filter.phone_no) {
        this.listLoading = true
        try {
          const data = {
            notification_id: this.filter.form_id,
            donor_name: this.filter.donor_name,
            referral_by: this.filter.referral_name,
            donor_phone: this.filter.phone_no,
          }
          const service = new donorService()
          const response = await service.donorSearchList(data)
          if (response.status == 200) {
            this.donorList = response.data
          } else {
            this.donorList = []
          }
          this.listLoading = false
        } catch (e) {
          console.log(e)
        }
      } else {

        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
    },
  },
}
</script>
