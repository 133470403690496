<template>
  <v-dialog v-model="show" persistent :retain-focus="false" max-width="1140">
    <v-card>
      <v-card-title class="justify-center">
        <span class="text-h5"><strong>EDIT DONOR NOTIFICATION</strong></span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="createdDate" outlined hide-details dense label="Date: DD-MM-YYYY HH:mm"
                readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
            </v-col>

            <v-col cols="12" md="2">
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-if="item.mobile_notification !== null" v-model="createdDateMobile" outlined dense
                label="Mobile Date: DD-MM-YYYY HH:mm (Notification)" readonly hide-details>
              </v-text-field>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="item.donor_name" outlined dense hide-details label="Donor's Name"
                required></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0">Parent/Guardian:</p>
              <v-radio-group row class="mt-0" v-model="item.s_w_d_of">
                <v-radio name="Parents_name" label="S" value="Son"></v-radio>
                <v-radio name="Parents_name" label="W" value="Wife"></v-radio>
                <v-radio name="Parents_name" label="D" value="Daughter"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field outlined dense hide-details v-model="item.donor_parent_guardian_name"
                label="Parent/Guardian Name" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="item.age" outlined dense hide-details label="Donor's Age" required
                @keypress="onlyAge"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <p class="mb-0">Sex:</p>
              <v-radio-group row class="mt-0" v-model="item.sex">
                <v-radio name="sex" label="Male" value="Male"></v-radio>
                <v-radio name="sex" label="Female" value="Female"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea v-model="item.postal_address" outlined dense hide-details label="Postal Address" required
                    rows="6" row-height="15"></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-radio-group row class="mt-0" v-model="item.urban_rural">
                    <v-radio name="sex" label="Urban" value="Urban"></v-radio>
                    <v-radio name="sex" label="Rural" value="Rural"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="item.chapters" outlined dense :items="CenterCity" label="Handling Chapters"
                    item-text="chapter"></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field v-model="item.phone_no" outlined dense label="Phone No" required counter="10"
                    @keypress="onlyNumber"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field outlined dense hide-details v-model="item.donor_relative_name" label="Relative Name"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                  <p class="mb-0">Relative relation with Donor:</p>
                  <v-radio-group row class="mt-0" v-model="item.relation_with_donor" v-on="relationWithDonor()">
                    <v-radio name="donor_relation" label="Son" value="Son"></v-radio>
                    <v-radio name="donor_relation" label="Wife" value="Wife"></v-radio>
                    <v-radio name="donor_relation" label="Daughter" value="Daughter"></v-radio>
                    <v-radio name="donor_relation" label="Others" value="Others"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="12" v-if="item.relation_with_donor === 'Others'" class="pt-0">
                  <v-text-field outlined dense :rules="nameee" hide-details v-model="item.donorOtherRelationshipName"
                    label="Relationship Name*" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense hide-details v-model="item.referral_name" label="Referral Name" required
                    readonly></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense label="Referral Contact No." required
                    v-model="item.referral_contact_number" counter="10" @keypress="onlyNumber" readonly></v-text-field>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" md="6">
              <p class="mb-0">Requesting Person</p>
              <v-radio-group row class="mt-0" v-model="item.category_type_of_the_requesting_person" readonly>
                <v-radio name="Parents_name" label="Eye Bank Staff" value="Eye Bank Staff"></v-radio>
                <v-radio name="Parents_name" label="Hospital Staff" value="Hospital Staff"></v-radio>
                <v-radio name="Parents_name" label="Volunteer" value="Volunteer"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="item.cause_of_death" outlined dense label="Cause of Death" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-datetime-picker v-model="deathDate" time-format="HH:mm" date-format="dd-MM-yyyy"
                label="Date & Time of Death DD-MM-YYYY HH:mm" :text-field-props="textFieldProps"></v-datetime-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="samedata()"> Cancel </v-btn>
        <v-btn color="primary" @click="editDonorNoti()" :disabled="!formIsValid"> Update </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>

import donorService from '@/service/Donor.service'
import moment from 'moment'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const userId = localData.user_id

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      user_id: userId,
      textFieldProps: {
        outlined: true,
        dense: true,
        'hide-details': true,
      },
    }
  },

  props: ['visible', 'item', 'method', 'CenterCity'],

  computed: {

    formIsValid() {
      return (
        this.item.donor_name &&
        this.item.donor_parent_guardian_name &&
        this.item.s_w_d_of &&
        this.item.age &&
        this.item.sex &&
        this.item.postal_address &&
        this.item.phone_no &&
        this.item.donor_relative_name &&
        this.item.relation_with_donor &&
        this.item.referral_name &&
        this.item.referral_contact_number &&
        this.item.category_type_of_the_requesting_person
      )
    },

    deathDate: {
      get() {
        return moment.utc(this.item.date_of_time_of_death).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.item, 'date_of_time_of_death', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

    createdDate: {
      get() {
        return moment(this.item.date).subtract('05:30').format('DD-MM-YYYY HH:mm')
      },
    },

    createdDateMobile: {
      get() {
        return moment(this.item.mobile_notification).subtract("05:30").format('DD-MM-YYYY HH:mm')
      },
    },

    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },

  },
  watch: {
    options: {
      handler() {
        // this.GetCenter()
      },
      deep: true,
    },
  },
  async mounted() {
    // this.GetCenter()

  },
  methods: {

    relationWithDonor() {
      if (this.item.relation_with_donor != "Others") {
        this.item.donorOtherRelationshipName = ""
      }
    },

    checkData(e) {
      console.log("shubham", e)
    },

    samedata() {
      this.show = false
      this.method()
    },

    same(item) {
      this.editDonorNoti()
      this.$router.push({ name: 'Form_A', params: { donor_id: item.donor_id } });

    },

    // async GetCenter() {
    //   const service = new Centerservice()
    //   let response = await service.BranchesList()
    //   console.log("all branches list : ", response)

    //   if (response) {
    //     (response.data).map((item, i) => {
    //       // console.log("items ::::::::: ", item)
    //       response.data[i].chapter = `${item.center_name} (${item?.city})`
    //     });
    //     this.CenterCity = response.data
    //   } else {
    //     this.CenterCity = []
    //   }
    // },

    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault();
      }
    },

    onlyAge($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault();
      }
    },
    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      // console.log("hello",response)
    },

    async editDonorNoti() {
      if (this.item.phone_no.length < 10) {
        return false,
          this.snackbarText = "Phone number should be 10 character.",
          this.snackbar = true
      } else if (this.item.referral_contact_number.length < 10) {
        return false,
          this.snackbarText = "Referral phone number should be 10 character.",
          this.snackbar = true
      }
      else if (this.item.relation_with_donor == "Others" && this.item.donorOtherRelationshipName == "" || this.item.donorOtherRelationshipName === null) {
        return false,
          this.snackbarText = "Please fill relationship name.",
          this.snackbar = true
      }
      try {

        let str = this.item.chapters;

        let spl = (str.split("("))[1];

        let chapters = ((str.split("("))[0]).trim();

        let center_city = (spl.split(")"))[0];

        const data = {
          date: moment(this.item.date).format('YYYY-MM-DD HH:mm'),
          donor_name: this.item.donor_name,
          s_w_d_of: this.item.s_w_d_of,
          donor_parent_guardian_name: this.item.donor_parent_guardian_name,
          age: this.item.age,
          sex: this.item.sex,
          postal_address: this.item.postal_address,
          urban_rural: this.item.urban_rural,
          phone_no: this.item.phone_no,
          cause_of_death: this.item.cause_of_death,
          date_of_time_of_death: moment.utc(this.item.date_of_time_of_death).format('YYYY-MM-DD HH:mm'),
          donor_relative_name: this.item.donor_relative_name,
          relation_with_donor: this.item.relation_with_donor,
          referral_name: this.item.referral_name,
          referral_contact_number: this.item.referral_contact_number,
          category_type_of_the_requesting_person: this.item.category_type_of_the_requesting_person,
          donor_id: this.item.donor_id,
          chapters: chapters,
          center_city: center_city,
          user_id: this.user_id,
          donorOtherRelationshipName: this.item.donorOtherRelationshipName
        }
        const service = new donorService()
        const response = await service.updateDonorNotification(data)
        if (response.status == 200) {
          this.snackbarText = response.message,
            this.snackbar = true
          setTimeout(() => {
            this.show = false
          }, 1000)
          this.$router.push({ name: 'Form_A', params: { donor_id: this.item.donor_id } });
        } else {
          this.snackbarText = response.message,
            this.snackbar = true
        }
      } catch (e) {
        console.log(e)
      }
    },

  },
}
</script>
